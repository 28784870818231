// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
@import url("https://use.typekit.net/rud0xal.css");

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// jQuery
@import '~jquery-ui/themes/base/all.css';

// International Mobile
@import 'intl-tel-input/build/css/intlTelInput.css';

// Bootstrap
@import 'variables';
@import '~bootstrap/scss/bootstrap';

// Defaults
html, body, .tooltip, .popover {
    font-family: $font-family-sans-serif;
}
h1,h2,h3,h4,h5,h6{
    font-family: $font-family-serif;
}
code, kbd, pre, samp, .text-monospace {
    font-family: $font-family-monospace;
}
button, input, optgroup, select, textarea {
    font-family: $font-family-base;
}
.btn{
    text-transform: uppercase;
    letter-spacing: .1rem;
    min-width: 150px;
}
.pointer:hover{
    cursor: pointer;
}

// errors
label.error {
    color: red;
    font-size: .8rem;
    display: block;
    margin-top: 5px;
}
input.error {
    border: 1px solid red;
    font-weight: 300;
    color: red;
}

// swal button themeing
.swal2-loader.ocl-loader {
    width: 150px;
    height: 150px;
    animation: none;
    border-width: 0;
}
.ocl-spinner {
    width: 150px;
    height: 150px;
}

//fix blurry logo images
.high-quality-image{
    image-rendering: -webkit-optimize-contrast; //mac safari
    will-change: transform; //chrome windows
}
.trusted-by-image{
    height: 45px;
}

// height helpers
.height-2-rem{
    height: 2rem;
}
.height-45-px{
    height: 45px;
}
.height-40-px{
    height: 40px;
}
.height-105-px{
    height: 105px;
}
.height-100-px{
    height: 100px;
}

// duo mfa
.duo-iframe{
    min-height: 500px;
}

// intlTelInput
.iti{
    width: 100%;
}
.swal-iti{
    overflow: visible !important;
    z-index: 2 !important;
}

// card styles
.card-sale{
    color: white;
    background: repeating-linear-gradient(45deg, $primary, $primary 25px, #f44336 25px, #942502 calc(2 * 25px));
}
