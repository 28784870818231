$enable-negative-margins: true;

// Colors
$primary: #e24621;
$secondary: #b2eaee;
$tertiary: #f9e5da;
$success: #198754;
$info: #edfafb;
$warning: #ffc107;
$danger: #dc3545;
$light: #fafafa;
$dark: #343a40;
$neutral: #adb5bd;

// Theme colors
$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning,
    danger: $danger,
    light: $light,
    dark: $dark,
);

// Custome colors
$custom-colors: (
  "tertiary": $tertiary,
  "neutral": $neutral,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// Fonts
$font-family-sans-serif: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      'p22-mackinac-pro', Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

// Border Radius
$border-radius: .7rem;

// Component Colors
$component-active-color: $dark !default;
$component-active-bg: $secondary !default;

// Buttons
$btn-padding-y: .7rem;
$btn-padding-x: .7rem;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: .8rem;
$btn-font-weight: bold;
$btn-border-radius: .7rem;

// Buttons Small
$btn-padding-y-sm: .5rem;
$btn-padding-x-sm: .5rem;
$btn-font-size-sm: .8rem;
$btn-border-radius-sm: .7rem;

// Buttons Large
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 1rem;
$btn-font-size-lg: .8rem;
$btn-border-radius-lg: .7rem;

// Colour Ratios
$min-contrast-ratio: 4 !default; //force burnt orange to contrast with white text

// List Group
$list-group-bg: $light;
$list-group-hover-bg: rgba($secondary, .5);

// Card
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

// Input
$input-padding-y: .7rem;
